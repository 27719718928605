import { Component, OnInit, ViewChild } from '@angular/core';
import { JobApiService, PersonApiService, DictionaryApiService, PersonCustomApiService, RegionApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, JobView, GridRequestModel, JobViewModel, JobViewFilterModels, PersonView, PersonViewFilterModels, PersonViewModel, PersonType, BaseDropDownViewModel, PersonTypeViewModel, RoleType, Person, ClinicAuditorViewModel, UserFileViewModel, UserFileItemGridViewModel, SearchFilter, ChangeLoginModel } from '../../../../models/index';
import { HashTable, Helper, fullDoctorName, fullPersonName } from '../../../../Helper/index';
import { retry, debounceTime } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { NotificationService } from '../../../services';
import { DatePipe } from '@angular/common';
import { IGridOptions, ControlType } from '../../../components/grid/grid.component';
import { ModalWindownModule } from "../../../components/modal-window/modal-window.module";
/* import { debounce } from 'rxjs/operator/debounce'; */

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less'],
  providers: [DatePipe],
  /* imports: [ModalWindownModule] */
})
export class UsersComponent implements OnInit {

  users: BaseViewGridModel<PersonViewModel>;
  fieldOptions: HashTable<IGridOptions>;
  gridRequestModel: FilteredGridRequestViewModel<PersonViewFilterModels>;
  showDialog: boolean;
  showQrScaner: boolean;
  selectedModel: PersonViewModel;
  hasntSalary: boolean;

  roles: BaseDropDownViewModel<number>[];
  filterRoles: BaseDropDownViewModel<number>[];
  selectedRole: BaseDropDownViewModel<number>;
  selectedFilterRole: BaseDropDownViewModel<number>;
  personTypesList = RoleType;

  regions: BaseDropDownViewModel<number>[];
  selectedRegion: BaseDropDownViewModel<number>;

  currencyList: BaseDropDownViewModel<number>[];

  isEditMode = false;
  helper: Helper;

  showDoctorDialog: boolean;
  doctorRole: BaseDropDownViewModel<number>;
  selectedDoctorModel: PersonViewModel;

  positions: BaseDropDownViewModel<number>[];
  workTypes: BaseDropDownViewModel<number>[];
  selectedPosition: BaseDropDownViewModel<number>;
  selectedWorkType: BaseDropDownViewModel<number>;

  showChangeLoginDialog: boolean;
  selectedLogin: PersonViewModel = null;

  @ViewChild('clinicModalComponent') clinicComponent;

  constructor(
    private personService: PersonApiService,
    private notify: NotificationService,
    private datePipe: DatePipe,
    private dictionarySerivce: DictionaryApiService,
    private personCustomService: PersonCustomApiService,
    private regionService: RegionApiService
  ) {
    this.helper = new Helper();
    this.users = {
      viewModelList: []
    } as BaseViewGridModel<PersonViewModel>;

    this.hasntSalary = true;
    this.fieldOptions = {
      loginName: {
        title: 'Логин'
      },
      lastName: {
        title: 'Фамилия'
      },
      firstName: {
        title: 'Имя'
      },
      middleName: {
        title: 'Отчество'
      },
      personType: {
        title: 'Роль'
      },
      positionName: {
        title: 'Должность'
      },
      workTypeName: {
        title: 'Цех'
      },
      address: {
        title: 'Адрес'
      },
      /*       phone: {
              title: 'Телефон'
            }, */
      email: {
        title: 'Email'
      },
      dateCreation: {
        title: 'Дата создания',
        fieldType: {
          type: ControlType.customValue,
          value: (item) => this.transformDate(this.helper.convertUtcStringToLocaleDate(item.dateCreation))
        }
      },
      firedDate
        : {
        title: 'Дата увольнения',
        fieldType: {
          type: ControlType.customValue,
          value: (item) => this.transformDate(this.helper.convertUtcStringToLocaleDate(item.firedDate))
        }
      },
      regionName: {
        title: 'Клиника'
      },
      regionAddress: {
        title: 'Адрес региона'
      },
      viberCode: {
        title: 'Code'
      },
      viberUserId: {
        title: 'vid'
      },
      sendViberMessage: {
        title: 'sms',
        fieldType: {
          type: ControlType.customValue,
          value: (item) => item.sendViberMessage ? 'Да' : ''
        }
      },
      generate: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Изменить логин',
          class: 'btn btn-danger',
          click: (item: PersonViewModel) => this.changeLogin(item)
        },
        order: false
      }
    };

    this.gridRequestModel = {
      filter: {
        filterModel: {}
      }
    } as FilteredGridRequestViewModel<PersonViewFilterModels>;

    this.showDialog = false;
    this.selectedModel = {} as PersonViewModel;

    this.regions = [];
    this.selectedRegion = {} as BaseDropDownViewModel<number>;

    this.roles = [];
    this.selectedRole = {} as BaseDropDownViewModel<number>;
    this.selectedFilterRole = {} as BaseDropDownViewModel<number>;

    this.currencyList = [];

    this.doctorRole = { id: RoleType.Doctor } as BaseDropDownViewModel<number>;
    this.selectedDoctorModel = {
      personTypeId: RoleType.Doctor
    } as PersonViewModel;
  }

  ngOnInit() {
    forkJoin(
      this.dictionarySerivce.getPersonTypes(),
      this.dictionarySerivce.getClinicList(),
      this.dictionarySerivce.getCurrencyTypes(),
      this.dictionarySerivce.getWorkTypes(),
      this.dictionarySerivce.getPositions()
    ).subscribe(pair => {
      let personTypes = pair[0] as BaseDropDownViewModel<number>[];
      this.roles = [];
      this.roles.push(...personTypes);

      this.filterRoles = [];
      this.filterRoles.push({ name: "Все" } as BaseDropDownViewModel<number>);
      this.filterRoles.push(...personTypes);


      let regions = pair[1] as BaseDropDownViewModel<number>[];
      this.regions = [];
      this.regions.push(...regions);

      let currencies = pair[2] as BaseDropDownViewModel<number>[];
      this.currencyList = [];
      this.currencyList.push(...currencies);

      let workTypes = pair[3] as BaseDropDownViewModel<number>[];
      this.workTypes = [];
      this.workTypes.push(...workTypes);

      let positions = pair[4] as BaseDropDownViewModel<number>[];
      this.positions = [];
      this.positions.push(...positions);
    });

    this.initGrid();
  }

  initGrid = () => {
    this.personService.getAll(this.gridRequestModel).subscribe((data) => {
      this.users = data;
      this.users.viewModelList.forEach(s => {
        s.birthdayDate = new Date(s.birthdayDate);
      });
    });
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = { ...model as FilteredGridRequestViewModel<PersonViewFilterModels>, filter: this.gridRequestModel.filter };
    this.initGrid();
  }

  addUser = (): void => {
    this.isEditMode = false;
    this.selectedModel = {} as PersonViewModel;
    this.selectedModel.password = this.getPassword(4);

    this.regionService.getNewPrefix().subscribe(s => {
      this.selectedModel.prefix = s;
    });
    this.selectedRegion = {} as BaseDropDownViewModel<number>;
    this.selectedRole = {} as BaseDropDownViewModel<number>;
    this.showDialog = true;
  }

  rowClickEvent = (model: PersonViewModel) => {
    this.selectedModel = { ...model };
    this.selectedRegion = this.regions.filter(s => s.id == this.selectedModel.regionId)[0] || {} as BaseDropDownViewModel<number>;
    this.selectedRole = this.roles.filter(s => s.id == this.selectedModel.personTypeId)[0] || {} as BaseDropDownViewModel<number>;
    this.showDialog = true;
    this.isEditMode = true;

    console.log(this.selectedModel);
  }

  onFilterRoleChange = ($event: BaseDropDownViewModel<number>) => {
    this.selectedFilterRole = $event;
    this.onSearchClick();
    //this.initGrid();
  }

  onSearchClick = () => {
    this.gridRequestModel.filter.filterModel.roleId = this.selectedFilterRole.id == 0 ? null : this.selectedFilterRole.id;
    this.initGrid();
  }

  closeDialog = ($event) => {
    this.isEditMode = false;
    this.showDialog = false;
  }

  OnRoleChange = ($event: BaseDropDownViewModel<number>) =>
    this.selectedRole = $event;

  OnRegionChange = ($event: BaseDropDownViewModel<number>) =>
    this.selectedRegion = $event;

  saveDialog = (): void => {
    console.log(this.selectedModel);
    console.log(this.selectedModel.birthdayDate);
    this.selectedModel.regionId = this.selectedRegion.id;
    this.selectedModel.personTypeId = this.selectedRole.id;
    this.selectedModel.phone = this.selectedModel.loginName;
    this.personService.save(this.selectedModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.showDialog = false;

      this.initGrid();
    });
  }

  createAndAddDoctorToClinick() {
    this.showDialog = false;
    this.showDoctorDialog = true;
    this.selectedDoctorModel.password = this.getPassword(4);
  }

  closeDoctorDialog = ($event) => {
    this.hideDoctorDialog();
  }

  saveDoctorDialog = ($event) => {
    console.log(this.selectedDoctorModel);
    this.selectedDoctorModel.createRegionId = this.selectedModel.regionId;

    this.personService.save(this.selectedDoctorModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.hideDoctorDialog();
      this.clinicComponent.initClinicDoctorList(this.selectedModel.regionId);
    });
  }

  changeLogin = (item: PersonViewModel) => {
    console.log(item);
    this.selectedLogin = { ...item };
    this.showChangeLoginDialog = true;
  }

  saveChangeLoginDialog = ($event) => {
    const model = new ChangeLoginModel();
    model.id = this.selectedLogin.personId;
    model.login = this.selectedLogin.loginName;
    model.password = this.selectedLogin.password;

    this.personService.changeLogin(model).subscribe(s => {
      this.notify.showInfo("Логин изменее успешно");
      this.initGrid();
      this.showChangeLoginDialog = false;
      this.selectedLogin = null;
    });
  }

  closeChangeLoginDialog = ($event) => {
    this.showChangeLoginDialog = false;
    this.selectedLogin = null;
  }

  changeLoginIsValid = () => {
    return this.selectedLogin && this.selectedLogin.loginName && this.selectedLogin.password;
  }

  private hideDoctorDialog = () => {
    this.showDoctorDialog = false;
    setTimeout(() => { this.showDialog = true; }, 10);
  }

  private getPassword = (len: number) => {
    let pass = "";
    let symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!№;%:?*()_+=";
    for (let i = 0; i < len; i++) {
      pass += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    return pass;
  }

}
