﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IModel } from "./Dental-DataModel-Interfaces-IModel";
import { RoleType } from "./Dental-DataModel-CustomTypes-RoleType";
import { Region } from "./Dental-DataModel-Models-Region";
import { WorkType } from "./Dental-DataModel-Models-WorkType";
import { Position } from "./Dental-DataModel-Models-Position";
import { Employee } from "./Dental-DataModel-Models-Employee";

export class Person  implements IModel {
    
    id: number;
    personTypeId: number;
    role: RoleType;
    firstName: string;
    lastName: string;
    middleName: string;
    address: string;
    phone: string;
    email: string;
    region: Region;
    regionId?: number;
    dateCreation: any;
    userCreateId?: number;
    lastEditDate?: any;
    lastUserEditId?: number;
    settings: string;
    isFired: boolean;
    firedDate?: any;
    birthdayDate?: any;
    description: string;
    workTypeId?: number;
    workType: WorkType;
    positionId?: number;
    position: Position;
    viberCode: string;
    viberUserId: string;
    sendViberMessage: boolean;
    employee: Employee;
}




  
