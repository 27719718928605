﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


import { Injectable } from "@angular/core";
import { ResponseContentType, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { BasApiService } from '../base.api.service';
import {GridApiService} from './grid.api.service';
import * as ApiTypes from "../../models";

@Injectable()
export class PersonApiService extends GridApiService<ApiTypes.PersonViewModel,ApiTypes.PersonViewFilterModels>{
  
  constructor(public baseService: BasApiService) {
   super(baseService);
    this.apiKey = "api/person";
  }
  
  getCurrent = (): Observable<ApiTypes.Person> =>
    this.baseService.get<ApiTypes.Person>(`api/Person/current`, {}, false, true);   
  
  getRegisteredPersosnsByJobId = (id: number): Observable<ApiTypes.PersonModel[]> =>
    this.baseService.get<ApiTypes.PersonModel[]>(`api/Person/registered/${id}`, {}, true, true);   
  
  getPersonsByClinikId = (id: number): Observable<ApiTypes.Person[]> =>
    this.baseService.get<ApiTypes.Person[]>(`api/Person/clinik/${id}`, {}, true, true);   
  
  getPersonsByPositionId = (id: number): Observable<ApiTypes.PersonModel[]> =>
    this.baseService.get<ApiTypes.PersonModel[]>(`api/Person/position/${id}`, {}, true, true);   
  
  getPersonsByWorkTypeId = (id: number): Observable<ApiTypes.PersonModel[]> =>
    this.baseService.get<ApiTypes.PersonModel[]>(`api/Person/worktype/${id}`, {}, true, true);   
  
  getPersonsByWorkTypeIds = (model: ApiTypes.ItemStateModel): Observable<ApiTypes.PersonModel[]> =>
    this.baseService.post<ApiTypes.PersonModel[]>(`api/Person/worktypes`, model, {}, true, true);   
  
  getTechnicians = (): Observable<ApiTypes.PersonModel[]> =>
    this.baseService.get<ApiTypes.PersonModel[]>(`api/Person/technicians`, {}, true, true);   
  
  saveSettings = (settings: string): Observable<void> =>
    this.baseService.put<void>(`api/Person/settings?settings=${encodeURIComponent(settings)}`, null, {}, true, true);   
  
  save = (model: ApiTypes.PersonViewModel): Observable<void> =>
    this.baseService.post<void>(`api/Person`, model, {}, true, true);   
  
  savePerson = (model: ApiTypes.PersonModel): Observable<void> =>
    this.baseService.post<void>(`api/Person/onlyPerson`, model, {}, true, true);   
  
  getFreeDoctorList = (): Observable<ApiTypes.BaseDropDownViewModel<number>[]> =>
    this.baseService.get<ApiTypes.BaseDropDownViewModel<number>[]>(`api/Person/getfreedoctorlist`, {}, true, true);   
  
  addDoctorToClinic = (model: ApiTypes.PersonViewModel): Observable<void> =>
    this.baseService.post<void>(`api/Person/adddoctortoclinic`, model, {}, true, true);   
  
  removeDoctorFromClinic = (id: number): Observable<void> =>
    this.baseService.put<void>(`api/Person/removedoctorfromclinic/${id}`, null, {}, true, true);   
  
  addClinicToAuditor = (model: ApiTypes.ClinicAuditorViewModel): Observable<void> =>
    this.baseService.post<void>(`api/Person/addclinictoauditor`, model, {}, true, true);   
  
  removeClinicFromAuditor = (model: ApiTypes.ClinicAuditorViewModel): Observable<void> =>
    this.baseService.post<void>(`api/Person/removeclinicfromauditor`, model, {}, true, true);   
  
  getClinicListByAuditor = (id: number): Observable<ApiTypes.ClinicAuditorViewModel[]> =>
    this.baseService.get<ApiTypes.ClinicAuditorViewModel[]>(`api/Person/getcliniklictbyauditor/${id}`, {}, true, true);   
  
  getAvailableClinics = (id: number): Observable<ApiTypes.BaseDropDownViewModel<number>[]> =>
    this.baseService.get<ApiTypes.BaseDropDownViewModel<number>[]>(`api/Person/getavailableclinics/${id}`, {}, true, true);   
  
  uploadUserFile = (model: ApiTypes.UserFileViewModel): Observable<void> =>
    this.baseService.post<void>(`api/Person/uploaduserfile`, model, {}, true, true);   
  
  getUserFiles = (id: number): Observable<ApiTypes.UserFileItemGridViewModel[]> =>
    this.baseService.get<ApiTypes.UserFileItemGridViewModel[]>(`api/Person/getuserfiles/${id}`, {}, true, true);   
  
  deleteUserFile = (id: number): Observable<void> =>
    this.baseService.post<void>(`api/Person/deleteuserfile/${id}`, null, {}, true, true);   
  
  downloadFile = (fileId: number): Observable<void> =>
    this.baseService.get<void>(`api/Person/${fileId}`, {}, true, true);   
  
  savePersonWorkType = (model: ApiTypes.ItemStateModel): Observable<void> =>
    this.baseService.put<void>(`api/Person/worktype`, model, {}, true, true);   
  
  savePersonPosition = (model: ApiTypes.ItemStateModel): Observable<void> =>
    this.baseService.put<void>(`api/Person/position`, model, {}, true, true);   
  
  changeLogin = (model: ApiTypes.ChangeLoginModel): Observable<void> =>
    this.baseService.post<void>(`api/Person/changelogin`, model, {}, true, true);   
  
  }

