import { Component, OnInit } from '@angular/core';
import { JobApiService, PersonApiService, SalaryApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, JobView, GridRequestModel, JobViewModel, JobViewFilterModels, PersonModel, SalaryViewModel, SalaryArchiveViewFilterModels } from '../../../../models/index';
import { HashTable, fullDoctorName, fullPersonName, fullName, Helper } from '../../../../Helper/index';
import { NotificationService } from '../../../services';
import { DatePipe } from '@angular/common';
import { IGridOptions } from '../../../components/grid/grid.component';
import { debug } from 'util';
import { ActivatedRoute } from '@angular/router';
import { fail } from 'assert';

class ExtJobView extends JobViewModel {
  doctorFullName: string;
  fulNumber: string;
  protez: string;
  enrichedDueDate: string;
  removableProtez: string;
}

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
  providers: [DatePipe]
})
export class HomeComponent implements OnInit {

  jobs: BaseViewGridModel<ExtJobView>;
  fieldOptions: HashTable<IGridOptions>;
  gridRequestModel: FilteredGridRequestViewModel<JobViewFilterModels>;
  showDialog: boolean;
  showQrScaner: boolean;
  selectedModel: ExtJobView;
  registeredPersonsNames: PersonModel[];
  hasntSalary: boolean;
  headerTitle: string;
  salaryModels: SalaryViewModel[];
  showSalaryDialog: boolean;
  archiveSalaryModels: SalaryViewModel[];
  showArhiveDialog: boolean = false;
  helper: Helper;
  archiveRequest: SalaryArchiveViewFilterModels;

  constructor(
    private service: JobApiService,
    private personService: PersonApiService,
    private notify: NotificationService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private sallaryApi: SalaryApiService
  ) {
    this.helper = new Helper();
    this.jobs = {
      viewModelList: []
    } as BaseViewGridModel<ExtJobView>;

    this.hasntSalary = true;
    this.showSalaryDialog = false;
    this.fieldOptions = {
      fulNumber: {
        title: 'Номер наряда'
      },
      clinicName: {
        title: 'Клиника'
      },
      enrichedDueDate: {
        title: 'Срок'
      },
      protez: {
        title: 'Протез'
      },
      compulsory: {
        color: 'custom-color',
        hide: true
      }
    };
    this.gridRequestModel = {} as FilteredGridRequestViewModel<JobViewFilterModels>;

    this.showDialog = false;
    this.selectedModel = {} as ExtJobView;
    this.registeredPersonsNames = [];

    this.headerTitle = this.route.snapshot.data.headerTitle;

    /* const defaultDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

    this.archiveRequest = {
      orderNum: '',
      start: defaultDate,
      end: defaultDate
    } as SalaryArchiveViewFilterModels; */

    this.archiveRequest = {
    } as SalaryArchiveViewFilterModels;
  }

  ngOnInit() {
    this.initGrid();
    this.initSalary();
  }

  initGrid = () => {
    this.service.getAll(this.gridRequestModel).subscribe((data) => {
      this.jobs = { ...data } as BaseViewGridModel<ExtJobView>;
      this.jobs.viewModelList.forEach(s => {
        s.doctorFullName = fullDoctorName(s);

        let grouped: HashTable<string[]> = {};
        s.protezes.forEach((val) => {
          if (!grouped[val.protezName]) {
            grouped[val.protezName] = [];
          }
          grouped[val.protezName].push(val.protezName);
        });
        s.fulNumber = `${s.clinicPrefix}-${s.number}`;
        s.protez = Object.keys(grouped).map((key) => `${key}-${grouped[key].length}шт`).join('; ').trim();
        s.dueDate = this.transformDateForTechnician(this.helper.convertUtcStringToLocaleDate(s.dueDate));
        s.enrichedDueDate = s.dueDate;

        let removableGrouped: HashTable<string[]> = {};
        let removable = s.protezes.filter((protez) => protez.protezTypeId == 1).forEach((val) => {
          if (!removableGrouped[val.protezName]) {
            removableGrouped[val.protezName] = [];
          }
          removableGrouped[val.protezName].push(val.protezName);
        });

        s.removableProtez = Object.keys(removableGrouped).map((key) => `${key}-${grouped[key].length}шт`).join('; ').trim();
      });
    });
  }

  initSalary = () => {
    this.sallaryApi.getIsAccruedSallary().subscribe(data => {
      let hasNotConfirmed = data.filter(x => x.isAccrued).length > 0;
      if (hasNotConfirmed) {
        data = data.filter(x => x.isAccrued);
      }

      if (data.length > 0) {
        this.salaryModels = [...data];
        this.hasntSalary = false;
      } else {
        this.salaryModels = [];
        this.hasntSalary = true;
      }
    });
  }

  getArchiveSalaries = () => {
    if (this.archiveRequest.start) {
      this.archiveRequest.start.setHours(0, 0, 0, 0);
    }

    if (this.archiveRequest.end) {
      this.archiveRequest.end.setHours(0, 0, 0, 0);
    }

    this.sallaryApi.getArchiveSalary(this.archiveRequest).subscribe(s => {
      this.archiveSalaryModels = [...s];
    });
  }

  onArchiveSearchClick = () => {
    this.getArchiveSalaries();
  }

  transformDateForTechnician = (date): string => {
    let dt = new Date(date);
    let hours = dt.getHours();

    if (hours < 9) {
      date = new Date(dt.setDate(dt.getDate() - 1)).setHours(19, 0, 0, 0);
    }
    else if (hours >= 9 && hours < 14) {
      date = dt.setHours(9, 0, 0, 0);
    }
    else if (hours >= 14 && hours < 19) {
      date = dt.setHours(14, 0, 0, 0);
    }
    else if (hours >= 19) {
      date = dt.setHours(19, 0, 0, 0);
    }

    return this.transformDate(date);
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = model as FilteredGridRequestViewModel<JobViewFilterModels>;
    this.initGrid();
  }

  rowClickEvent = (model: ExtJobView) => {
    this.selectedModel = model;
    this.personService.getRegisteredPersosnsByJobId(this.selectedModel.jobId).subscribe((result) => {
      this.registeredPersonsNames = result;
    }, _ => _, () => this.showDialog = true);
  }

  closeDialog = ($event) => this.showDialog = false;
  closeScanerDialog = ($event) => this.showQrScaner = false;

  runScanner = ($event) => {
    this.showQrScaner = true;
  }

  scannedMessage = ($event: number) => {
    console.log('scannedMessage');
    console.log($event);
    this.service.registerOrder($event).subscribe((resultMessage) => {
      this.notify.showInfo(resultMessage);
      this.initGrid();
    });
  }

  caclcSalary = ($event) => {
    this.showSalaryDialog = true;
    this.initSalary();
  }

  closeSalaryDialog = () => this.showSalaryDialog = false;

  fullName = fullName;

  onSalaryConfirm = (id: number) => {
    this.sallaryApi.userConfirmSallary(id).subscribe(s => {
      this.initSalary();
    });
  }

  showArchiveClick = ($event) => {
    this.showArhiveDialog = true;
    this.getArchiveSalaries();
  }

  closeArchiveDialog = () => this.showArhiveDialog = false;
}
