import { Component, OnInit, Output, EventEmitter, NgZone, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgLog, Camera } from '../../../Helper';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

@NgLog()
@Component({
  selector: 'qr-scaner',
  templateUrl: './qr-scaner.component.html'
})
export class QrScanerComponent implements OnInit {
  @Input() debug: boolean = false;
  @Output() change = new EventEmitter<string>();

  hasDevices: boolean;
  hasPermission: boolean;
  currentDevice: MediaDeviceInfo;
  cameras: MediaDeviceInfo[];
  readonly cameraIdKey = 'cameraId';

  @ViewChild('scanner') scanner: ZXingScannerComponent;

  constructor(private zone: NgZone) {
    this.cameras = [];
  }

  ngOnInit() {
    this.InitScaner();
  }

  InitScaner = (): void => {
    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.hasDevices = true;
      this.cameras = devices;
      console.log(this.cameras);

      //selects the devices's back camera by default
      for (const device of devices) {
        if (/back|rear|environment/gi.test(device.label)) {
          this.scanner.changeDevice(device);
          this.currentDevice = device;
          break;
        }
      }

      const cameraId = localStorage.getItem(this.cameraIdKey);
      if (cameraId) {
        const cam = this.cameras.find(s => s.deviceId == cameraId);
        if (cam) {
          this.currentDevice = cam;
        }

      } else if (!this.currentDevice) {
        this.currentDevice = this.cameras[0];
      }
    });

    //this.scanner.scanComplete.subscribe((result: any) => console.log(result));

    this.scanner.camerasNotFound.subscribe(() => this.hasDevices = false);
    this.scanner.permissionResponse.subscribe((perm: boolean) => this.hasPermission = perm);
  }

  handleQrCodeResult(resultString: string) {
    console.log('Result: ', resultString);
    this.change.emit(resultString);
  }

  switchCamera = (camId: string): void => {
    console.log(camId);
    this.currentDevice = this.scanner.getDeviceById(camId);
    localStorage.setItem(this.cameraIdKey, this.currentDevice.deviceId);
  }
}
