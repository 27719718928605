import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BodyService } from './services/index';
import { setTheme } from 'ngx-bootstrap/utils';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ruLocale } from 'ngx-bootstrap/locale';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  /* title = 'app works!'; */


  constructor(translate: TranslateService,
    private bodyService: BodyService,
    private localeService: BsLocaleService) {
    translate.setDefaultLang('ru');
    setTheme('bs4');
    defineLocale('ru', ruLocale);
    this.localeService.use('ru');
  }

  @HostListener('document:click', ['$event'])
  onClik(event: MouseEvent) {
    this.bodyService.emitClick(event);
  }
}
