﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


  export enum RoleType { 
      None = 0,
      Admin = 1,
      Clinic = 2,
      Technician = 3,
      Auditor = 4,
      Doctor = 5,
      TaskMaster = 6,
      SuperAdmin = 10,
  }
