import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientFooterComponent } from './client-footer.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ClientFooterComponent],
    exports: [ClientFooterComponent]
})
export class ClientFooterModule { }
