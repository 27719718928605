import { Component, OnInit } from '@angular/core';
import { BaseViewGridModel, JobViewModel, JobViewFilterModels, FilteredGridRequestViewModel, GridRequestModel } from '../../../../models';
import { JobApiService } from '../../../../api';
import { HashTable, Helper, fullDoctorName } from '../../../../Helper';
import { DatePipe } from '@angular/common';
import { IGridOptions } from '../../../components/grid/grid.component';

class ExtJobView extends JobViewModel {
  doctorFullName: string;
  fulNumber: string;
  protez: string;
  enrichedDueDate: string;
  removableProtez: string;
}

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  providers: [DatePipe]
})
export class HomeComponent implements OnInit {
  jobs: BaseViewGridModel<ExtJobView>;
  gridRequestModel: FilteredGridRequestViewModel<JobViewFilterModels>;
  fieldOptions: HashTable<IGridOptions>;
  helper: Helper;

  constructor(private service: JobApiService, private datePipe: DatePipe) {
    this.helper = new Helper();
    this.jobs = {
      viewModelList: []
    } as BaseViewGridModel<ExtJobView>;

    this.gridRequestModel = {} as FilteredGridRequestViewModel<JobViewFilterModels>;

    this.fieldOptions = {
      patient: {
        title: 'Пациент'
      },
      protez: {
        title: 'Протез'
      },
      fulNumber: {
        title: 'Номер наряда'
      },
      enrichedDueDate: {
        title: 'Срок'
      },
      compulsory: {
        color: 'custom-color',
        hide: true
      }
    };
  }

  ngOnInit() {
    this.initGrid();
  }

  initGrid = () => {
    this.service.getAll(this.gridRequestModel).subscribe((data) => {
      this.jobs = { ...data } as BaseViewGridModel<ExtJobView>;
      this.jobs.viewModelList.forEach(s => {
        s.doctorFullName = fullDoctorName(s);

        let grouped: HashTable<string[]> = {};
        s.protezes.forEach((val) => {
          if (!grouped[val.protezName]) {
            grouped[val.protezName] = [];
          }
          grouped[val.protezName].push(val.protezName);
        });
        s.fulNumber = `${s.clinicPrefix}-${s.number}`;
        s.protez = Object.keys(grouped).map((key) => `${key}-${grouped[key].length}шт`).join('; ').trim();
        s.enrichedDueDate = this.transformDate(this.helper.convertUtcStringToLocaleDate(s.dueDate));

        let removableGrouped: HashTable<string[]> = {};
        let removable = s.protezes.filter((protez) => protez.protezTypeId == 1).forEach((val) => {
          if (!removableGrouped[val.protezName]) {
            removableGrouped[val.protezName] = [];
          }
          removableGrouped[val.protezName].push(val.protezName);
        });

        s.removableProtez = Object.keys(removableGrouped).map((key) => `${key}-${grouped[key].length}шт`).join('; ').trim();
      });
    });
  }

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = model as FilteredGridRequestViewModel<JobViewFilterModels>;
    this.initGrid();
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

}
