import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// Import our authentication service
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
/* import 'rxjs/add/operator/first';
import 'rxjs/add/operator/map'; */
import { Person, RoleType } from '../../models/index';
import { map, first } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.auth.getUserData().pipe(
      map(user => {
        return this.routeValidation(user as Person, state)
      })
    ).pipe(first());
  }

  /*   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
      return this.auth.getUserData().map(user => {
        return this.routeValidation(user, state)
      }).first();
    } */

  routeValidation = (user: Person, state: RouterStateSnapshot): boolean => {
    if (user) {
      switch (user.role) {

        case RoleType.Auditor:
          if (state.url.indexOf("/auditor") == -1)
            this.router.navigate(['auditor']);
          return true;

        case RoleType.Technician:
          if (state.url.indexOf("/techincian") == -1)
            this.router.navigate(['techincian']);
          return true;

        case RoleType.Clinic:
        case RoleType.Doctor:
          if (state.url.indexOf("/clinic") == -1)
            this.router.navigate(['clinic']);
          return true;

        case RoleType.SuperAdmin:
        case RoleType.Admin:
          if (state.url.indexOf("/administrator") == -1)
            this.router.navigate(['administrator']);
          return true;

        case RoleType.TaskMaster:
          if (state.url.indexOf("/taskmaster") == -1)
            this.router.navigate(['taskmaster']);
          return true;

        default:
          {
            if (state.url != '/') {
              this.router.navigate(['']);
              return false;
            }
            else
              return true;
          }

      }
    } else {
      if (state.url != '/') {
        this.router.navigate(['']);
        return false;
      }
      else
        return true;
    }
  }
}
