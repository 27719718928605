import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../services/index';

@Component({
  selector: 'layout-auth',
  templateUrl: './layout-auth.component.html',
  styleUrls: ['./layout-auth.component.less']
})
export class LayoutAuthComponent implements OnInit {

  menuIsOpen: boolean;

  constructor(private menuSerivice: MenuService) {
    this.menuIsOpen = false;
  }

  ngOnInit() {
    this.menuSerivice.getState().subscribe((state) => {
      this.menuIsOpen = state;
    });
  }
}
