import { Component, EventEmitter, Output, OnChanges, SimpleChanges, OnInit, OnDestroy, Inject, Input, ViewChild, AfterViewInit, ContentChildren, QueryList } from '@angular/core';
import { Text } from '@angular/compiler/src/i18n/i18n_ast';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'modal-window',
  templateUrl: './modal-window.component.html'
})
export class ModalWindowComponent implements OnChanges, OnInit, OnDestroy {

  @Output() closeDialogEvent = new EventEmitter<MouseEvent>();
  @Output() applyDialogEvent = new EventEmitter<MouseEvent>();
  @Input() isSaveVisible: boolean;
  @Input() isSaveDisabled = false;
  @Input() applyButtonTitle: string;
  @Input() className: string;
  @Input() applyClassName: string;
  @Input() closeClassName: string;

  //should be false if dialog called from dialog
  @Input() removeShowDialogClass = true;

  @ViewChild(NgForm) public form: NgForm;
  @ContentChildren(NgModel) public models: QueryList<NgModel>;

  submited: boolean;

  constructor(@Inject("windowObject") private window: Window) {
    this.window.document.body.classList.add('show-dialog');
    this.isSaveVisible = false;
    this.applyButtonTitle = "Save";
    this.applyClassName = "btn-primary";
    this.closeClassName = "btn-primary";
    this.submited = false;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnDestroy(): void {
    if (this.removeShowDialogClass) {
      this.window.document.body.classList.remove('show-dialog');
    }
  }

  close = (event: MouseEvent) => {
    this.closeDialogEvent.emit(event);
  }
  save = (event: MouseEvent) => this.applyDialogEvent.emit(event);
}
