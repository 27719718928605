import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthService } from '../../services/index';
import { Person } from '../../../models';

@Component({
  selector: 'app-client-footer',
  templateUrl: './client-footer.component.html',
  styleUrls: ['./client-footer.component.less']
})
export class ClientFooterComponent implements OnInit {
  viberCode: string;
  viberUrl: SafeUrl;
  person: Person;

  constructor(private authSerivice: AuthService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.authSerivice.getUserData().subscribe(s => {
      this.person = { ...s };

      this.viberUrl = this.sanitize('viber://pa?chatURI=adentalstudio&text=' + this.person.viberCode);
    });
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
