import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
//import { Response, Headers, RequestOptions, BaseRequestOptions, ResponseContentType, RequestOptionsArgs } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { TranslationService } from '../app/services/translation.service';
import { NotificationService } from '../app/services/notification.service';
import { HttpErrorModel } from '../models/index';
import { BadRequestModel } from '../models/server/Dental-WebCore-Models-Infrastructure-BadRequestModel';

/* export class CustomRequestOptions extends BaseRequestOptions {
  headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
  });
} */

export class CustomRequestOptions {
  headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
  });
}

interface IBasApiService {
  get: <T>(url: string, config: CustomRequestOptions, hasCatchedErrors: boolean) => Observable<T>;
  post: <T>(url: string, data: any, config: CustomRequestOptions) => Observable<T>;
  delete: <T>(url: string, config: CustomRequestOptions) => Observable<T>;
  put: <T>(url: string, data: any, config: CustomRequestOptions) => Observable<T>;
  download: (url: string, data: any, config: CustomRequestOptions) => Observable<Blob>;
}


@Injectable()
export class BasApiService implements IBasApiService {

  constructor(private http: HttpClient, private notify: NotificationService,
    private router: Router, private zone: NgZone, private translationService: TranslationService) { }

  private CoverObserver = <T>(httpObserver: Observable<any>, hasCatchedErrors: boolean = true, isVoid: boolean = false): Observable<T> => {
    let httpEvent: Subject<T> = new Subject<T>();
    let httpEventObserver: Observable<T> = httpEvent.asObservable();
    httpObserver.subscribe(recent => {
      this.zone.run(() => {
        let response = recent;

        //if (response.text()) {
        //  var jsonData = response.json();
        //  let castedData = <T>jsonData;
        //  httpEvent.next(castedData);
        //}
        if (response) {
          var jsonData = response;
          let castedData = <T>jsonData;
          httpEvent.next(castedData);
        }
        else {
          if (isVoid) {
            httpEvent.next(undefined);
          } else {
            if (hasCatchedErrors) {
              this.notify.showError(this.translationService.translate("ServerError.InternalError"));
            }
            else {
              httpEvent.error(response);
            }
          }
        }

      });
      httpEvent.complete();
    }, error => {
      if (hasCatchedErrors)
        this.errorResolver(error);
      else {
        httpEvent.error(error);
      }
      httpEvent.complete();
    });
    return httpEventObserver;
  }

  get = <T>(url: string, config: any = {}, hasCatchedErrors: boolean = true, isVoid: boolean = false): Observable<T> => {
    if (config == null) {
      config = {};
    }
    config.headers = new Headers({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    });
    return this.CoverObserver<T>(this.http.get(url, config), hasCatchedErrors, isVoid);
  }

  post = <T>(url: string, data: any, config = {}, hasCatchedErrors: boolean = true, isVoid: boolean = false): Observable<T> =>
    this.CoverObserver<T>(this.http.post(url, data, config), hasCatchedErrors, isVoid);


  put = <T>(url: string, data: any, config = {}, hasCatchedErrors: boolean = true, isVoid: boolean = false): Observable<T> =>
    this.CoverObserver<T>(this.http.put(url, data, config), hasCatchedErrors, isVoid);


  delete = <T>(url: string, config: any = {}, hasCatchedErrors: boolean = true, isVoid: boolean = false): Observable<T> =>
    this.CoverObserver<T>(this.http.delete(url, config), hasCatchedErrors, isVoid);

  download = (url: string, data: any, config = {}): Observable<Blob> =>
    this.http.post(url, data, { responseType: "blob" });

  errorResolver = (error: Response): any => {
    console.log(error);
    if (error.status == 400) {
      let message: string = this.errorMessage(error);
      this.notify.showError(message);
    }
    if (error.status == 500) {
      console.error(error);
      this.notify.showError(this.translationService.translate("ServerError.InternalError"));
    }
    if (error.status == 403) {
      this.notify.showWarning(this.translationService.translate("ServerError.PermissionDeny"));
    }
    if (error.status == 401) {
      this.router.navigate(['/']);
    }
  }

  errorMessage = (error: Response): string => {
    //let ovj: BadRequestModel = JSON.parse(error["_body"]) as BadRequestModel;
    let ovj: BadRequestModel = {} as BadRequestModel;
    if (error["_body"]) {
      ovj = JSON.parse(error["_body"]) as BadRequestModel;
    }
    else if (error["error"]) {
      ovj = error["error"] as BadRequestModel;
    }

    if (typeof ovj === 'string') {
      return ovj
    }

    let text = this.translationService.translate(ovj.key);
    if (!!ovj.message)
      text = `${text}<br/>${ovj.message}`;
    return text;
  }
}
