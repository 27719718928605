﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IModel } from "./Dental-DataModel-Interfaces-IModel";
import { Person } from "./Dental-DataModel-Models-Person";

export class Employee  implements IModel {
    
    id: number;
    loginName: string;
    loginHash: string;
    isLocked: boolean;
    person: Person;
}




  
